import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import VimeoPlayer from '../../../components/vimeoplayer/vimeoplayer'
import Callus from '../../../components/callus/callus'
// import ButtonBlocks from "../../../components/buttonblocks/buttonblocks"
// import ButtonBlock from "../../../components/buttonblock/buttonblock"
import Reviewscriptform from "../../../components/activecampaign/reviewscriptform/reviewscriptform"


let colorYellow = {
    color: 'yellow'
}

let colorWhite = {
    color: 'white'
}

const IndexPage = () => (

  <Layout>

    <SEO title="Why your Google Reviews are not showing up - Five Star Review System" />

    <VimeoPlayer videoId='404869153' autoplay={true}/>

    <Callus />

    <div className="form-message px-5 py-3 mx-auto text-center" style={{backgroundColor: "#00163f"}}>
        <span className="h3 " style={colorYellow}>Fill in the form to receive Google COVID-19 updates and to receive your free <span style={colorWhite}>"How To Ask For Google Reviews"</span> script.</span>
    </div>
    <Reviewscriptform
      lb='covid-19-reviews-hidden'
    />

  </Layout>
)

export default IndexPage
